import classNames from "classnames";
import React from "react";

const FullPageLoader: React.FC<{ opaqueBackground?: boolean }> = ({ opaqueBackground = false }) => {
  return (
    <div className="full-page-loader-container">
      <div className={classNames("full-page-loader", { "full-page-loader-opaque": opaqueBackground })}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default FullPageLoader;
