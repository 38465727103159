import { useLayoutEffect, useState } from "react";

export const useSetCssPropertiesOnRoot = (
  variables: Record<string, string | undefined>,
  apply: boolean = true,
): boolean => {
  const shouldApply = apply && Object.keys(variables).length > 0;
  const [loading, setLoading] = useState(shouldApply);

  const rootStyle = document.documentElement.style;
  useLayoutEffect(() => {
    if (shouldApply) {
      Object.entries(variables).map(([key, value]) => rootStyle.setProperty(key, value ?? null));
      setLoading(false);
    }
  }, [shouldApply, variables, setLoading, rootStyle]);
  return loading;
};
