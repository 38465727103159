import { useLayoutEffect } from "react";
import { STORAGE_KEYS } from "../modules/common/constants/Storage";
import useLocalStorage from "../modules/common/hooks/useLocalStorage";

const DARK_MODE_CLASS = "dark-mode";

const THEME_COLOR_LIGHT = "#ffffff";
const THEME_COLOR_DARK = "#1C1E20";

export enum DESIGN_MODE {
  auto = "auto",
  light = "light",
  dark = "dark",
}

const systemSettingDarkMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

export const useDesignMode = () => useLocalStorage(STORAGE_KEYS.DESIGN_SETTING, DESIGN_MODE.auto);

const isDarkMode = (designMode: DESIGN_MODE): boolean => {
  switch (designMode) {
    case "dark":
      return true;
    case "auto":
      return systemSettingDarkMediaQuery.matches;
    case "light":
    default:
      return false;
  }
};

export const useDarkMode = () => {
  const [designMode] = useDesignMode();
  return isDarkMode(designMode);
};

const setDarkModeClass = (darkMode: boolean) => {
  const htmlClassList = document.documentElement.classList;
  const metaThemeColor = document.head.querySelector('meta[name="theme-color"]');

  if (darkMode) {
    htmlClassList.add(DARK_MODE_CLASS);
  } else {
    htmlClassList.remove(DARK_MODE_CLASS);
  }

  metaThemeColor?.setAttribute("content", darkMode ? THEME_COLOR_DARK : THEME_COLOR_LIGHT);
};
export const useInitDarkMode = () => {
  const darkMode = useDarkMode();
  useLayoutEffect(() => setDarkModeClass(darkMode), [darkMode]);
};
