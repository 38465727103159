import isNil from "./isNil";
import { sortedUrlParams } from "./sortedUrlParams";

export type QueryParam = string | (string | null)[] | number | number[] | undefined | null;
export type QueryParams = Record<string, QueryParam>;

function getQueryStringFromParams(queryParams: QueryParams) {
  const queryParamEntries: [string, string | string[]][] = Object.entries(queryParams)
    .filter(tuple => !isNil(tuple[1]))
    .map(([key, value]) => [key, Array.isArray(value) ? value.map(x => `${x}`) : `${value}`]);

  const params = new URLSearchParams();
  queryParamEntries.forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => {
        params.append(key, item);
      });
    } else {
      params.append(key, `${value}`);
    }
  });

  return params.toString();
}

export function getAlphabeticalQueryStringFromParams(queryParams: QueryParams) {
  return sortedUrlParams(getQueryStringFromParams(queryParams));
}

export default getQueryStringFromParams;
