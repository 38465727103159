import i18next from "i18next";
import { Warranty, WarrantyType } from "../types/warranty";
import Locale from "../../common/constants/Locale";
import { Formatters } from "../../common/hooks/useFormatter";
import { logger } from "../../common/scripts/logger";

function getWarrantyText(warranty: Warranty, locale: Locale, { formatDate, formatNumber }: Formatters): string {
  const warrantyItems = [
    warranty.months ? i18next.t("MONTHS PLACEHOLDER", { value: warranty.months }) : null,
    warranty.maxKm ? i18next.t("KM PLACEHOLDER", { value: formatNumber(warranty.maxKm) }) : null,
  ];
  const warrantyItemsText = warrantyItems.filter(item => item !== null).join(` ${i18next.t("OR")} `);

  const text = warranty.text[locale];
  const warrantyText = text ? `: ${text}` : "";

  switch (warranty.typeId) {
    case WarrantyType.FROM_TAKEOVER:
      return `${i18next.t("WARRANTY FROM TAKEOVER")} ${warrantyItemsText}${warrantyText}`;
    case WarrantyType.FROM_REGISTRATION:
      return `${i18next.t("WARRANTY FROM REGISTRATION")} ${warrantyItemsText}${warrantyText}`;
    case WarrantyType.FROM_DATE:
      const warrantyDate = formatDate(new Date(warranty.date));
      const warrantyFullText = warrantyItemsText
        ? `. ${i18next.t("MAXIMUM", { value: warrantyItemsText })}${warrantyText}`
        : warrantyText;
      return `${i18next.t("WARRANTY FROM DATE")} ${warrantyDate}${warrantyFullText}`;
    default:
      logger.warn(`unknown warranty type ${(warranty as any).type}`);
      return "";
  }
}

export default getWarrantyText;
