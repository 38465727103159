import React, { ReactNode, useCallback, useMemo } from "react";
import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  differenceInCalendarYears,
  differenceInHours,
} from "date-fns";
import { Currency } from "../../hygraph/vo";
import { DEFAULT_CURRENCY } from "../constants/Currency";
import { createNullableContext } from "../helpers/contextCreator";
import { useLocale } from "./useLocale";

type FormatterInstances = {
  dateFormatter: Intl.DateTimeFormat;
  extensiveDateFormatter: Intl.DateTimeFormat;
  relativeDateFormatter: Intl.RelativeTimeFormat;
  timeFormatter: Intl.DateTimeFormat;
  numberFormatter: Pick<Intl.NumberFormat, "format">;
  currencyWithoutSuffixFormatter: Pick<Intl.NumberFormat, "format">;
  currencyFormatters: Record<Currency, Pick<Intl.NumberFormat, "format">>;
};

const [FormatterInstanceContext, useFormatterInstanceContext] =
  createNullableContext<FormatterInstances>("FormatterInstanceContext");

const DECIMAL_SEPARATOR = ".";

const numberFormatPartsToString = (parts: Intl.NumberFormatPart[]) => parts.map(part => part.value).join("");

const buildNumberFormatter = (locale: string, additionalOptions?: Parameters<typeof Intl.NumberFormat>[1]) => {
  const instance = new Intl.NumberFormat(locale, additionalOptions);

  const fixDecimalSeparator = (part: Intl.NumberFormatPart) => ({
    ...part,
    value: part.type === "decimal" ? DECIMAL_SEPARATOR : part.value,
  });
  const formatToPartsWithDecimalSeparator = (value: number) => instance.formatToParts(value).map(fixDecimalSeparator);
  return {
    formatToParts: formatToPartsWithDecimalSeparator,
    format: (value: number) => numberFormatPartsToString(formatToPartsWithDecimalSeparator(value)),
  };
};

const buildCurrencyFormatter = (locale: string, additionalOptions?: Parameters<typeof Intl.NumberFormat>[1]) => {
  const instance = buildNumberFormatter(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...additionalOptions,
  });

  // Replace zero fraction with currency shorthand
  // 100.00 -> 100.-
  const shortenFractionIfZero = (part: Intl.NumberFormatPart) => ({
    ...part,
    value: part.type === "fraction" && part.value === "00" ? "–" : part.value,
  });
  return {
    format: (value: number) => numberFormatPartsToString(instance.formatToParts(value).map(shortenFractionIfZero)),
  };
};

const initializer = (locale: string): FormatterInstances => {
  return {
    dateFormatter: new Intl.DateTimeFormat(locale, { dateStyle: "long" }),
    extensiveDateFormatter: new Intl.DateTimeFormat(locale, {
      weekday: "long",
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }),
    relativeDateFormatter: new Intl.RelativeTimeFormat(locale, { numeric: "auto", style: "long" }),
    timeFormatter: new Intl.DateTimeFormat(locale, { timeStyle: "short" }),
    numberFormatter: buildNumberFormatter(locale),
    currencyWithoutSuffixFormatter: buildCurrencyFormatter(locale),
    currencyFormatters: {
      CHF: buildCurrencyFormatter(locale, { style: "currency", currency: Currency.Chf }),
    },
  };
};

export const FormatterInstanceProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const locale = useLocale();

  const instance = useMemo(() => initializer(locale), [locale]);
  return <FormatterInstanceContext.Provider value={instance}>{children}</FormatterInstanceContext.Provider>;
};

export type Formatters = {
  formatDate: (date: string | Date) => string;
  // format as "Donnerstag, 21.7.2022"
  formatDateExtensive: (date: Date) => string;
  // format as n <unit> ago
  formatRelativeDate: (date: Date, relativeTo?: Date) => string;
  // time as string must be formatted as "hours:min:seconds", e.g. 16:00:00
  formatTime: (time: string | Date) => string;
  formatCurrency: (value: number, currency?: Currency | null) => string;
  formatCurrencyWithoutSuffix: (value: number) => string;
  formatNumber: (value: number) => string;
};

function useFormatter(): Formatters {
  const instance = useFormatterInstanceContext();
  return {
    formatDate: useCallback(
      date => {
        return instance.dateFormatter.format(typeof date === "string" ? new Date(date) : date);
      },
      [instance.dateFormatter],
    ),
    formatDateExtensive: useCallback(
      date => {
        return instance.extensiveDateFormatter.format(date);
      },
      [instance.extensiveDateFormatter],
    ),
    formatRelativeDate: useCallback(
      (date, relativeTo = new Date()) => {
        const hoursDifference = differenceInHours(relativeTo, date);
        let unit: Intl.RelativeTimeFormatUnit = "hour";
        let diff = hoursDifference;
        if (hoursDifference < 24) {
          unit = "hour";
          diff = hoursDifference;
        } else if (hoursDifference < 24 * 7) {
          unit = "day";
          diff = differenceInCalendarDays(relativeTo, date);
        } else if (hoursDifference < 24 * 30) {
          unit = "week";
          diff = differenceInCalendarWeeks(relativeTo, date);
        } else if (hoursDifference < 24 * 365) {
          unit = "month";
          diff = differenceInCalendarMonths(relativeTo, date);
        } else if (hoursDifference >= 24 * 365) {
          unit = "year";
          diff = differenceInCalendarYears(relativeTo, date);
        }
        return instance.relativeDateFormatter.format(-diff, unit);
      },
      [instance.relativeDateFormatter],
    ),
    formatTime: useCallback(
      time => {
        // T is required for Safari
        const date = typeof time === "string" ? new Date(`1970-01-01T${time}`) : time;
        return instance.timeFormatter.format(date);
      },
      [instance.timeFormatter],
    ),
    formatCurrency: useCallback(
      (value: number, currency?: Currency | null) => {
        const currentCurrency = currency ?? DEFAULT_CURRENCY;
        return instance.currencyFormatters[currentCurrency]?.format(value);
      },
      [instance.currencyFormatters],
    ),
    formatCurrencyWithoutSuffix: useCallback(
      (value: number) => {
        return instance.currencyWithoutSuffixFormatter.format(value);
      },
      [instance.currencyWithoutSuffixFormatter],
    ),
    formatNumber: useCallback(
      (value: number) => {
        return instance.numberFormatter.format(value);
      },
      [instance.numberFormatter],
    ),
  };
}

export default useFormatter;
