import React from "react";
import classNames from "classnames";
import i18next from "i18next";
import { Trans } from "react-i18next";
import { FilterReferenceData } from "../services/reference-data-aggregator/types";
import { VehicleSearchResponse } from "../../algolia/services/vehicleSearchApi";
import DialogPortal from "../../common/components/DialogPortal";
import IconButton from "../../visual-components/components/IconButton";
import Button, { LinkIconButton } from "../../visual-components/components/Button";
import { useIsInWhitelabelContext } from "../../../whitelabel/core/components/WhitelabelConfig";
import { useFilterStateContext } from "./filters/FilterStateContext";
import FilterRenderer from "./FilterRenderer";

type Props = {
  open: boolean;
  onClose: () => void;
  configurationCount: number;
  vehicleCount?: number;
  resetFilters: () => void;
  filterData?: FilterReferenceData;
  searchData: VehicleSearchResponse | undefined;
  showSaveSearchOverlay?: () => void;
  canHaveCustomTheme?: boolean;
};

const PowerSearch: React.FC<Props> = ({
  open,
  onClose,
  configurationCount,
  vehicleCount,
  resetFilters,
  filterData,
  searchData,
  showSaveSearchOverlay,
  canHaveCustomTheme,
}) => {
  const { openFilterId } = useFilterStateContext();
  const shouldResizeIframe = useIsInWhitelabelContext();

  return (
    <DialogPortal>
      <dialog
        className={classNames("power-search-overlay", { "custom-branding-theme": canHaveCustomTheme })}
        open={open}
        style={{ height: shouldResizeIframe ? "auto" : undefined }}
      >
        <div className="power-search" data-iframe-height={shouldResizeIframe || undefined}>
          <div className={classNames("power-search__container", { "mobile-nav-open": openFilterId !== null })}>
            <header className="power-search__header">
              <h1 className="power-search__title">
                {i18next.t("ALL FILTERS")}
                {configurationCount > 0 ? <span className="circle-counter">{configurationCount}</span> : null}
              </h1>
              <div className="power-search-header__link-wrap">
                {showSaveSearchOverlay ? (
                  <LinkIconButton icon="bell" onClick={showSaveSearchOverlay}>
                    {i18next.t("SAVE SEARCH")}
                  </LinkIconButton>
                ) : null}
                {configurationCount > 0 ? (
                  <LinkIconButton
                    icon="close"
                    onClick={e => {
                      e.preventDefault();
                      resetFilters();
                    }}
                  >
                    <Trans i18nKey="DELETE ALL FILTERS OPTIMIZED">
                      <span className="desktop-only" />
                    </Trans>
                  </LinkIconButton>
                ) : null}
              </div>
              <IconButton
                ariaLabel={i18next.t("CLOSE")}
                className="power-search__close"
                icon="close"
                onClick={onClose}
              />
            </header>
            <main className="power-search__body">
              {open && filterData ? (
                <FilterRenderer filterData={filterData} searchData={searchData} view="powerSearch" />
              ) : null}
            </main>
          </div>
          <footer className="power-search__footer">
            <Button center type="button" onClick={onClose}>
              {i18next.t("SHOW N VEHICLES", { value: vehicleCount })}
            </Button>
          </footer>
        </div>
      </dialog>
    </DialogPortal>
  );
};

export default PowerSearch;
