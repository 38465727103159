import i18next from "i18next";
import {
  BRAND_MODEL_QUERY_PARAM,
  FILTER_DEFINITIONS,
  VEHICLE_CONDITION_INSIDE_IDS,
  VEHICLE_CONDITION_QUERY_PARAM,
} from "../services/filterDefinition";
import { notNil } from "../../common/helpers/isNil";
import { FilterReferenceData } from "../services/reference-data-aggregator/types";
import { FILTER_TYPE_CONFIGURATION_MAP } from "../services/filterTypeConfiguration";
import { Formatters } from "../../common/hooks/useFormatter";
import { MODEL_BRAND_SEPARATOR } from "../services/filter-types/brandModelFilterTypeHelpers";
import { getConfigurationLabels } from "./filterTypeHelpers";

type SeoSpecialGenerator = {
  isApplicable: (brandModel: string, vehicleCondition: string) => boolean;
  generate: (brandModelName: string, count: number) => { title: string; description?: string };
};

const hasModel = (brandModel: string): boolean => brandModel.includes(MODEL_BRAND_SEPARATOR);

const brandModelNewGenerator: SeoSpecialGenerator = {
  isApplicable: (brandModel, vehicleCondition) =>
    hasModel(brandModel) && vehicleCondition === VEHICLE_CONDITION_INSIDE_IDS.NEW,
  generate: (brandModelName, count) => ({
    title: i18next.t("SEO TITLE BRAND MODEL NEW", { value: brandModelName }),
    description: i18next.t("SEO DESCRIPTION BRAND MODEL NEW", { value: brandModelName, count }),
  }),
};
const brandNewGenerator: SeoSpecialGenerator = {
  isApplicable: (brandModel, vehicleCondition) =>
    !hasModel(brandModel) && vehicleCondition === VEHICLE_CONDITION_INSIDE_IDS.NEW,
  generate: (brandModelName, count) => ({
    title: i18next.t("SEO TITLE BRAND NEW", { value: brandModelName }),
    description: i18next.t("SEO DESCRIPTION BRAND NEW", { value: brandModelName, count }),
  }),
};
const brandUsedGenerator: SeoSpecialGenerator = {
  isApplicable: (brandModel, vehicleCondition) =>
    !hasModel(brandModel) && vehicleCondition === VEHICLE_CONDITION_INSIDE_IDS.USED,
  generate: (brandModelName, count) => ({
    title: i18next.t("SEO TITLE BRAND USED", { value: brandModelName }),
    description: i18next.t("SEO DESCRIPTION BRAND USED", { value: brandModelName, count }),
  }),
};
const brandModelUsedGenerator: SeoSpecialGenerator = {
  isApplicable: (brandModel, vehicleCondition) =>
    hasModel(brandModel) && vehicleCondition === VEHICLE_CONDITION_INSIDE_IDS.USED,
  generate: (brandModelName, count) => ({
    title: i18next.t("SEO TITLE BRAND MODEL USED", { value: brandModelName }),
    description: i18next.t("SEO DESCRIPTION BRAND MODEL USED", { value: brandModelName, count }),
  }),
};

const generators: SeoSpecialGenerator[] = [
  brandModelNewGenerator,
  brandNewGenerator,
  brandUsedGenerator,
  brandModelUsedGenerator,
];

export const vehicleSearchSeoData = ({
  appliedFilters,
  count,
  filterData,
  formatters,
}: {
  appliedFilters: Record<string, string | string[]>;
  count: number;
  filterData: FilterReferenceData;
  formatters: Formatters;
}): { title: string; description?: string | undefined } | null => {
  const { brandModel, vehicleCondition } = appliedFilters;

  if (!brandModel || !vehicleCondition || brandModel.length !== 1 || vehicleCondition.length !== 1) {
    return null;
  }
  const allowedFilters = [BRAND_MODEL_QUERY_PARAM, VEHICLE_CONDITION_QUERY_PARAM];

  const hasOnlyAllowedFilters = Object.keys(appliedFilters)
    .filter(key => {
      return notNil(appliedFilters[key]);
    })
    .every(key => allowedFilters.includes(key));

  if (!hasOnlyAllowedFilters) {
    return null;
  }

  const match = generators.find(({ isApplicable }) => isApplicable(brandModel[0], vehicleCondition[0]));
  if (match) {
    const filterDefinition = FILTER_DEFINITIONS[BRAND_MODEL_QUERY_PARAM];
    const brandModelNames = getConfigurationLabels(
      FILTER_TYPE_CONFIGURATION_MAP[BRAND_MODEL_QUERY_PARAM],
      brandModel,
      formatters,
      filterDefinition,
      filterData,
    );
    const brandModelName = brandModelNames.join(" ");
    return match.generate(brandModelName, count);
  }
  return null;
};
