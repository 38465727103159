import i18next from "i18next";
import { ALL_FILTERS, FILTER_DEFINITIONS } from "..//services/filterDefinition";
import { FILTER_TYPE_CONFIGURATION_MAP } from "..//services/filterTypeConfiguration";
import { getActiveAppliedFilterItems } from "../helpers/getActiveAppliedFilterItems";
import useFormatter from "../../common/hooks/useFormatter";
import { FilterReferenceData } from "..//services/reference-data-aggregator/types";
import { getConfigurationLabels } from "../helpers/filterTypeHelpers";
import { vehicleSearchSeoData } from "../helpers/vehicleSearchSeoData";

const MAX_FILTER_FOR_TITLE = 4;

function useVehicleSearchTitle(
  appliedFilters: Record<string, string | string[]>,
  filterData?: FilterReferenceData,
  count: number = 0,
): {
  title: string;
  hasFilterTitle: boolean;
  specialSeoTitle?: string;
  count?: number;
  specialSeoDescription?: string;
} {
  const formatters = useFormatter();

  if (!filterData) {
    return { title: "", hasFilterTitle: false };
  }

  const appliedFilterEntries = Object.fromEntries(getActiveAppliedFilterItems(appliedFilters));
  const appliedFilterPriorities = ALL_FILTERS.map((queryParam): [string, string[] | string] => [
    queryParam,
    appliedFilterEntries[queryParam],
  ]).filter(([, val]) => !!val);

  const titles = appliedFilterPriorities
    .map(([queryParam, val]) => {
      const filterDefinition = FILTER_DEFINITIONS[queryParam];
      const filterType = FILTER_TYPE_CONFIGURATION_MAP[filterDefinition.type];

      return getConfigurationLabels(filterType, val, formatters, filterDefinition, filterData);
    })
    .flat();

  if (titles.length === 0) {
    return { title: i18next.t("VEHICLESEARCH"), hasFilterTitle: false };
  }
  const moreText = titles.length > MAX_FILTER_FOR_TITLE ? ` ${i18next.t("AND OTHER FILTERS")}` : "";

  const title = titles.slice(0, MAX_FILTER_FOR_TITLE).join(", ");

  const seoData = vehicleSearchSeoData({
    appliedFilters,
    count,
    filterData,
    formatters,
  });

  return {
    title: title.charAt(0).toUpperCase() + title.slice(1) + moreText,
    hasFilterTitle: true,
    specialSeoTitle: seoData?.title,
    specialSeoDescription: seoData?.description,
  };
}

export default useVehicleSearchTitle;
