import { gql } from "graphql-request";
import { Ad, Language } from "../vo";
import imageFragment from "../fragments/imageFragment";
import linkToUnionFragment from "../fragments/linkToUnionFragment";
import geoFragment from "../fragments/geoFragment";
import pageNestedSlugFragment from "../fragments/pageNestedSlugFragment";
import { hygraphApi } from "./hygraphApi";

/*
 * ${imageFragment}
 * ${pageNestedSlugFragment}
 * ${linkToUnionFragment}
 * ${geoFragment}
 * ${adQuery}
 */
const adQuery = gql`
  fragment AdQuery on Ad {
    __typename
    id
    campaign
    name
    languages
    showSponsoringTag
    publicationValidities {
      validFrom
      validUntil
    }
    headline
    subline
    negativeFontColor
    successCriteria {
      ... on Brand {
        __typename
        id
        name
        insideId
      }
      ... on Dealer {
        __typename
        id
        name
        insideId
      }
    }
    imageDesktop {
      ...ImageParts
    }
    imageMobile {
      ...ImageParts
    }
    ctaLink {
      to {
        ...LinkToUnion
      }
    }
    adTarget {
      __typename
      ... on AdTargetHero {
        maxShare
        geo {
          ...GeoFragment
        }
      }
      ... on AdTargetTile {
        pointe
        maxShare
        geo {
          ...GeoFragment
        }
        brands {
          insideId
        }
        models {
          insideId
        }
        bodyTypeGroups {
          groupId
          bodyTypes {
            insideId
          }
        }
        vehicleConditions {
          insideId
        }
        fuelTypeGroups {
          groupId
        }
      }
      ... on AdTargetTileHomeRow {
        pointe
        maxShare
        geo {
          ...GeoFragment
        }
      }
    }
  }
`;

export const adApi = hygraphApi.injectEndpoints({
  endpoints: builder => ({
    getAd: builder.query<Ad | null, { currentLanguage: Language; id: string }>({
      providesTags: ["languageDependent"],
      query: ({ currentLanguage, id }) => {
        return {
          document: gql`
            ${imageFragment}
            ${pageNestedSlugFragment}
            ${linkToUnionFragment}
            ${geoFragment}
            ${adQuery}
            query Ad($id: ID!) {
              ad(where: { id: $id }) {
                ...AdQuery
              }
            }
          `,
          variables: { currentLanguage, id },
        };
      },
      transformResponse: (response: { ad: Ad | null }) => response?.ad ?? null,
    }),
    getAds: builder.query<Ad[] | null, { currentLanguage: Language }>({
      providesTags: ["languageDependent"],
      query: ({ currentLanguage }) => {
        return {
          document: gql`
            ${imageFragment}
            ${pageNestedSlugFragment}
            ${linkToUnionFragment}
            ${geoFragment}
            ${adQuery}
            query Ads($currentLanguage: Language!) {
              ads(where: { languages_contains_some: [$currentLanguage] }) {
                ...AdQuery
              }
            }
          `,
          variables: { currentLanguage },
        };
      },
      transformResponse: (response: { ads: Ad[] | null }) => response?.ads ?? null,
    }),
  }),
});

export const { useGetAdQuery, useGetAdsQuery } = adApi;
