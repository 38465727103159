import { useCallback, useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { ArrayParam, NumberParam, QueryParamConfig, StringParam, useQueryParams } from "use-query-params";
import { useGetVehiclesQuery } from "../services/vehicleSearchApi";
import { useFilterReferenceDataQuery } from "../../vehicle-search/services/vehicleFilterReferenceDataApi";
import { ALL_FILTERS, FILTER_DEFINITIONS } from "../../vehicle-search/services/filterDefinition";
import { FILTER_TYPE_CONFIGURATION_MAP } from "../../vehicle-search/services/filterTypeConfiguration";
import { DEFAULT_SORT_CRITERIA, getSortOptions } from "../../vehicle-search/services/sortOptions";
import { getFilterDefMap } from "../../vehicle-search/helpers/facetApplierHelpers";
import { FACET_KEY_FILTER_MAP } from "../../vehicle-search/services/facetKeyFilterMap";
import useEvent from "../../common/hooks/useEvent";
import { SearchParams } from "../../../router/constants/SearchParams";
import { getNewQueryParams } from "../../vehicle-search/helpers/getNewQueryParams";
import { getAlgoliaFilters } from "../../vehicle-search/helpers/getAlgoliaFilters";
import getQueryStringFromParams from "../../common/helpers/getQueryStringFromParams";
import { PAGE_QUERY_PARAM } from "../../vehicle-search/hooks/useFilterQueryParam";

export const SEARCH_QUERY_PARAM = SearchParams.searchQuery;
export const SORT_QUERY_PARAM = SearchParams.sortBy;

export const DEFAULT_VEHICLE_SEARCH_PAGE_SIZE = 30;

export function useAllVehicleSearchQueryParams() {
  return ALL_FILTERS.map(queryParam => FILTER_DEFINITIONS[queryParam]).reduce<Record<string, QueryParamConfig<any>>>(
    (agg, filterDefinition) => {
      agg[filterDefinition.queryParam] =
        FILTER_TYPE_CONFIGURATION_MAP[filterDefinition.type].paramType === "stringArray" ? ArrayParam : StringParam;
      return agg;
    },
    {},
  );
}

const getQueryParamMapFromString = (queryString: string) => {
  const params: Record<string, string[]> = {};

  const queryParams = new URLSearchParams(queryString);
  for (const key of queryParams.keys()) {
    params[key] = queryParams.getAll(key);
  }

  return params;
};

function useVehicleSearchData({
  pageSize = DEFAULT_VEHICLE_SEARCH_PAGE_SIZE,
  dealerRestrictions,
  brandRestrictions,
  searchTermString,
  contexts,
}: {
  pageSize?: number;
  dealerRestrictions?: number[];
  brandRestrictions?: number[];
  searchTermString?: string;
  contexts?: string[];
} = {}) {
  const { data: filterReferenceData } = useFilterReferenceDataQuery();

  const allQueryParams = useAllVehicleSearchQueryParams();

  const [queryParams, setAllQueryParams] = useQueryParams({
    ...allQueryParams,
    [SEARCH_QUERY_PARAM]: StringParam,
    [PAGE_QUERY_PARAM]: NumberParam,
    [SORT_QUERY_PARAM]: StringParam,
  });
  const updateSearchParams = useCallback(
    (params: Parameters<typeof setAllQueryParams>[0]) => {
      setAllQueryParams({
        [PAGE_QUERY_PARAM]: undefined,
        ...params,
      });
    },
    [setAllQueryParams],
  );

  const {
    [SEARCH_QUERY_PARAM]: persistedQuery,
    [PAGE_QUERY_PARAM]: queryPage,
    [SORT_QUERY_PARAM]: sortQuery,
    ...otherQueryParams
  } = queryParams;
  const page = queryPage || 0;

  const postedQuery = persistedQuery || "";
  const sortCriteria = sortQuery || DEFAULT_SORT_CRITERIA;

  const appliedFilters = searchTermString ? getQueryParamMapFromString(searchTermString) : otherQueryParams;

  const algoliaFilters = getAlgoliaFilters({
    appliedFilters,
    filterData: filterReferenceData,
    dealerRestrictions,
    brandRestrictions,
  });

  const algoliaOptions = {
    replica: sortCriteria === DEFAULT_SORT_CRITERIA ? undefined : sortCriteria,
    ...algoliaFilters,
  };

  const { currentData: vehicleData, isFetching } = useGetVehiclesQuery(
    algoliaFilters === undefined
      ? skipToken
      : {
          query: postedQuery,
          options: {
            ...algoliaOptions,
            page: page,
            hitsPerPage: pageSize,
            facets: ["*"],
            explain: ["params.rules"],
            clickAnalytics: true,
          },
          ruleContexts: contexts,
        },
  );

  // query reappliance logic
  const rules = vehicleData?.explain?.params?.rules;
  const reapplyQueryFilters = useEvent(() => {
    if (vehicleData && rules?.facetFilters && filterReferenceData) {
      const filterDefMap = getFilterDefMap(rules.facetFilters, FACET_KEY_FILTER_MAP);
      const newQueryParamEntries = getNewQueryParams(filterDefMap, filterReferenceData, appliedFilters);
      updateSearchParams(newQueryParamEntries);

      setPostedQuery(vehicleData.query.trim());
    }
  });
  useEffect(() => {
    if (rules?.facetFilters) {
      reapplyQueryFilters();
    }
  }, [rules, reapplyQueryFilters]);

  const setPostedQuery = useCallback(
    (query: string | undefined) => {
      let newQuery = query;
      // handle empty string as undefined
      if (newQuery !== undefined && newQuery.length === 0) {
        newQuery = undefined;
      }
      updateSearchParams({ [SEARCH_QUERY_PARAM]: newQuery });
    },
    [updateSearchParams],
  );

  const setPage = useCallback(
    (page: number | undefined) => {
      updateSearchParams({ [PAGE_QUERY_PARAM]: page === 0 ? undefined : page });
    },
    [updateSearchParams],
  );

  const sortOptions = getSortOptions();

  const setSortCriteria = useCallback(
    (sortCriteria: string) => {
      if (sortCriteria === DEFAULT_SORT_CRITERIA) {
        updateSearchParams({ [SORT_QUERY_PARAM]: undefined });
      } else {
        updateSearchParams({ [SORT_QUERY_PARAM]: sortCriteria });
      }
    },
    [updateSearchParams],
  );

  const totalPages = vehicleData?.nbPages;
  useEffect(() => {
    if (totalPages && totalPages < page) {
      setPage(undefined);
    }
  }, [totalPages, page, setPage]);

  const resetFilters = () => {
    const allQueryParams = Array.from(Object.keys(appliedFilters)).map(key => [key, undefined]);
    allQueryParams.push([SEARCH_QUERY_PARAM, undefined]);
    setAllQueryParams(Object.fromEntries(allQueryParams));
  };

  return {
    postedQuery,
    setPostedQuery,
    vehicleData,
    isFetching,
    filterData: filterReferenceData,
    appliedFilters,
    resetFilters,
    getQueryParams: () => getQueryStringFromParams(queryParams),
    setAllQueryParams,
    page,
    setPage,
    sortCriteria,
    setSortCriteria,
    sortOptions,
    algoliaOptions,
  };
}

export default useVehicleSearchData;
