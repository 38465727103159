import { createApi } from "@reduxjs/toolkit/query/react";
import { skipToken } from "@reduxjs/toolkit/query";
import { MultipleQueriesResponse, SearchResponse } from "@algolia/client-search";
import useLanguage from "../../../router/hooks/useLanguage";
import Locale from "../../common/constants/Locale";
import { Context } from "../../vehicle-search/helpers/contexts";
import { buildFilter } from "./buildFilter";
import { algoliaBaseQuery } from "./baseApi";
import { AlgoliaRequestOptions, buildIndexName, client, Indices } from "./search";
import { VehicleSearchResponseItem } from "./vehicleSearchApi";
import { ArticleResponseItem } from "./siteSearchApi";
import { DealerResponseItem } from "./dealerSearchApi";
import { BRAND_MODEL_FACETS, BrandModelModelId } from "./brandModelFacetKeys";
import { SITE_FACETS, SiteModelId } from "./siteFacetKeys";

const QUERY_SUGGESTIONS = "query_suggestions";
const SUGGESTIONS_HITS = 9;

export type BrandResponseItem = {
  objectID: string;
  name: string;
  image: string | null;
  brandSlug: string;
};

export type ModelResponseItem = {
  objectID: string;
  name: string;
  image: string | null;
  brand: string;
  brandSlug: string;
  modelSlug: string;
};

export type VehicleSuggestionsResponseItem = {
  query: string;
};

export type PageResponseItem = {
  objectID: string;
  title: string;
  slug: string;
  parentSlugs: string[];
  abstract: string;
  date: string;
  // used to build image url
  handle: string;
};

export type LiveSearchResponses = {
  vehicleData: SearchResponse<VehicleSearchResponseItem>;
  brandData: SearchResponse<BrandResponseItem>;
  modelData: SearchResponse<ModelResponseItem>;
  dealerData: SearchResponse<DealerResponseItem>;
  pageData: SearchResponse<PageResponseItem>;
  articleData: SearchResponse<ArticleResponseItem>;
  vehicleSuggestionData: SearchResponse<VehicleSuggestionsResponseItem>;
};

type LiveSearchQueryOptions = Omit<AlgoliaRequestOptions, "language">;

function searchAllIndices(query: string, { language }: { language: Locale }): Promise<MultipleQueriesResponse<any>> {
  return client.multipleQueries([
    {
      indexName: buildIndexName(Indices.vehicle, language),
      query,
      params: {
        hitsPerPage: 4,
        ruleContexts: [Context.AllVehicles],
      },
    },
    {
      indexName: buildIndexName(Indices.brandModel, language),
      query,
      params: {
        filters: buildFilter("modelId", BrandModelModelId.brand),
        hitsPerPage: 4,
      },
    },
    {
      indexName: buildIndexName(Indices.brandModel, language),
      query,
      params: {
        filters: buildFilter(BRAND_MODEL_FACETS.MODEL_ID, BrandModelModelId.model),
        hitsPerPage: 4,
      },
    },
    {
      indexName: buildIndexName(Indices.dealer, language),
      query,
      params: {
        hitsPerPage: 4,
      },
    },
    {
      indexName: buildIndexName(Indices.site, language),
      query,
      params: {
        filters: buildFilter(SITE_FACETS.MODEL_ID, SiteModelId.PAGE),
        hitsPerPage: 8,
      },
    },
    {
      indexName: buildIndexName(Indices.site, language),
      query,
      params: {
        filters: buildFilter(SITE_FACETS.MODEL_ID, SiteModelId.ARTICLE),
        hitsPerPage: 3,
      },
    },
    {
      indexName: buildIndexName(Indices.vehicle, language, QUERY_SUGGESTIONS),
      query,
      params: {
        hitsPerPage: SUGGESTIONS_HITS,
        ruleContexts: [Context.AllVehicles],
      },
    },
  ]);
}

export const liveSearchApi = createApi({
  reducerPath: "liveSearchApi",
  baseQuery: algoliaBaseQuery(searchAllIndices),
  endpoints: builder => ({
    liveSearch: builder.query<LiveSearchResponses, { query: string; options: AlgoliaRequestOptions }>({
      query: ({ query, options }) => ({
        query,
        options: {
          ...options,
        },
      }),
      transformResponse: (response: MultipleQueriesResponse<any>) => {
        const [vehicleData, brandData, modelData, dealerData, pageData, articleData, vehicleSuggestionData] =
          response.results;
        return {
          vehicleData,
          brandData,
          modelData,
          dealerData,
          pageData,
          articleData,
          vehicleSuggestionData,
        } as LiveSearchResponses;
      },
    }),
  }),
});

const useRawLiveSearchQuery = liveSearchApi.useLiveSearchQuery;

export const useLiveSearchQuery = (request: { query: string; options: LiveSearchQueryOptions } | typeof skipToken) => {
  const language = useLanguage();
  return useRawLiveSearchQuery(
    request === skipToken ? skipToken : { query: request.query, options: { ...request.options, language } },
  );
};
